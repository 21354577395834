
export default {
  props: {
    utc: Boolean,
  },
  computed: {
    lastModified() {
      return this.$config.DATE_GENERATED;
    },
  },
};
