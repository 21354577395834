
import lastModified from './lastModified';
export default {
  components: {
    lastModified,
  },
  data: () => ({
    utc: false,
  }),
  methods: {
    shortHash: (value) => (value ? value.substring(0, 7) : null),
  },
};
