
import AddToHomeScreen from '@/components/AddToHomeScreen.vue';
import UserMenu from '@/components/UserMenu.vue';
import TheFooter from '@/components/Layout/the-footer.vue';
import { mdiClipboardListOutline } from '@mdi/js';
export default {
  components: {
    AddToHomeScreen,
    UserMenu,
    TheFooter,
  },
  data() {
    return {
      loading: true,
      dialog: false,
      drawer: false,
    };
  },
  head() {
    const i18nSeo = this.$nuxtI18nSeo();
    return {
      htmlAttrs: {
        ...i18nSeo.htmlAttrs,
      },
      meta: [
        ...i18nSeo.meta,
        {
          hid: 'og:url',
          name: 'og:url',
          property: 'og:url',
          content: `${this.$config.BASE_URL}${this.$route.path}`,
        },
      ],
      link: [...i18nSeo.link],
    };
  },
  computed: {
    items() {
      return [
        {
          icon: '$book',
          text: this.$t('layout.navigation.recipes'),
          route: { name: 'recipes' },
        },
        {
          icon: '$store',
          text: this.$t('layout.navigation.products'),
          route: { name: 'products' },
        },
        {
          icon: mdiClipboardListOutline,
          text: 'Shopping List',
          route: { name: 'shoppinglist' },
        },
        {
          icon: '$calendar-check',
          text: 'Tasks',
          route: { name: 'tasks' },
        },
        {
          icon: '$settings',
          text: this.$t('layout.navigation.settings'),
          route: { name: 'settings' },
        },
      ];
    },
  },
  mounted() {
    if (process.client) {
      this.loading = false;
    }
  },
};
