export default [
  {
    dark: false,
    value: 'system',
  },
  {
    dark: false,
    value: 'light',
  },
  {
    dark: true,
    value: 'dark',
  },
];
