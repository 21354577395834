
import {
  mdiAccountCircle,
  mdiLogout,
  mdiThemeLightDark,
  mdiAccountGroup,
  mdiLogin,
} from '@mdi/js';
export default {
  data: () => ({
    menu: false,
    mdiAccountCircle,
    mdiLogout,
    mdiThemeLightDark,
    mdiAccountGroup,
    mdiLogin,
  }),
  computed: {
    isDark() {
      return this.$store.getters.getThemeById(this.currentTheme).dark;
    },
    currentTheme: {
      get() {
        return this.$colorMode.value;
      },
      set(value) {
        this.$colorMode.preference = value;
        // timeout required to wait for color mode to be set.
        setTimeout(
          () =>
            (this.$vuetify.theme.dark = this.$store.getters.getThemeById(
              this.$colorMode.value,
            ).dark),
          100,
        );
      },
    },
  },
};
